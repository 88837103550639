import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import moment from 'moment';

import { Layout, Alert, Collapse, Form, Input, Row, Col, Select, Button, message, Radio, DatePicker, InputNumber } from "antd";
import Navbar from '../../atoms/navbar';

import { loginRequest } from '../../config/api-config';

import { useMsal } from '@azure/msal-react';
import { Block, Confirm, Loading, Report } from 'notiflix';

import departmentHardCode from './data/department.json'
import categoryAsset from './data/asset-category.json'
import projectHardCode from './data/project.json'
import areaHardCode from './data/area.json'

function CreateCapex() {

    const [form] = Form.useForm();
    let navigate = useNavigate();

    const { instance, accounts } = useMsal();
    const { TextArea } = Input;

    const { Option } = Select;
    const { Panel } = Collapse;

    const [categoryAssetErp, setCategoryAssetErp] = useState(categoryAsset);
    const [departmentErp, setDepartment] = useState(departmentHardCode);
    const [projectErp, setProject] = useState(projectHardCode);
    const [areaErp, setAreaErp] = useState(areaHardCode);

    const [assetMaintenanceBy, setAssetMaintenanceBy] = useState(null);
    const [replacementOrNew, setReplacementOrNew] = useState(null);
    const [currentCurrency, setCurrentCurrency] = useState("IDR")
    const [assetOperateBy, setAssetOperateBy] = useState(null);
    const [attachedAsset, setAttachedAsset] = useState(null);
    const [financeStaff, setFinanceStaff] = useState(true);
    const handleReplacementOrNew = (a) => {
        setReplacementOrNew(a.target.value)
    }

    const handleAttachedAsset = (a) => {
        setAttachedAsset(a.target.value)
    }

    const handleAssetMaintenanceBy = (a) => {
        setAssetMaintenanceBy(a.target.value)
    }

    const handleAssetOperateBy = (a) => {
        setAssetOperateBy(a.target.value)
    }

    const handleCurrentCurrency = (value) => {
        setCurrentCurrency(value)
    }

    const onFinish = (data) => {
        console.log(data)
        // Confirm.show(
        //     'Important Confirmation',
        //     'Are you sure ?',
        //     'Yes',
        //     'No',
        //     () => {
        //         Loading.hourglass("Mohon tunggu sedang di proses.. ");
        //     })

    }

    const calculateTotalAmount = () => {
        const estimatedPrice = form.getFieldValue('estimated_price') || 0;
        const quantity = form.getFieldValue('qty') || 0;
        const total = estimatedPrice * quantity;
        form.setFieldsValue({ total_estimated: total });
    };

    const selectBefore = (
        <>
            <Select
                value={currentCurrency}
                name="currency"
                onChange={handleCurrentCurrency}
                style={{ width: 100 }}
            >
                <Option value="IDR">IDR</Option>
                <Option value="USD">USD</Option>
            </Select>
        </>
    );

    function RequestToken() {

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then((responseToken) => {

            })
            .catch((e) => {
            });
    }

    const getFormItemStyle = () => {
        return { backgroundColor: '#F0F3F7', padding: '8px', borderRadius: '4px' }

    };

    const onFinishFailed = () => {
        message.warning('Mohon lengkapi formulir.')
    }

    useEffect(() => {

        if (accounts.length > 0) {
            RequestToken()
        }

    }, [])

    return (
        <>
            <Navbar
                title="Create"
                back={true}
                subtitle="Capex Request"
                navigate="/home"
            ></Navbar>
            <div className="container-fluid white-back-cr">
                <Form
                    form={form}
                    name="service_request_form"
                    initialValues={{
                        remember: true,
                    }}
                    encType='multipart/form-data'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size='large'
                    layout='vertical'
                >
                    <div className="container p-3" style={{ alignSelf: "center" }}>
                        <div className="row">
                            <div className='col-md-12'>
                                <Alert
                                    message={<b style={{ fontSize: 15 }}>CAPITAL EXPENDITURE REQUEST</b>}
                                    description="Purchase of property, plant, and equipment "
                                    type="warning"
                                    className='mb-2'
                                />
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Your Identity</b>} key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label={"Date"}
                                                    name="date"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input date',
                                                        },
                                                    ]}
                                                    initialValue={moment()}
                                                >
                                                    <DatePicker style={{ pointerEvents: 'none' }} showTime format="YYYY-MM-DD" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Fullname"
                                                    name="fullname"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        size="middle"
                                                        placeholder="Pilih nama"
                                                        optionFilterProp="children"
                                                        onSelect={null}
                                                        filterOption={(inputValue, option) =>
                                                            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {[{ value: "10157", label: "Mohamad Fazrin Fahlevi" }].map(option => (
                                                            <Select.Option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Departement / Divisi"
                                                    name="department"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        size="middle"
                                                        placeholder="Select Department"
                                                        optionFilterProp="children"
                                                        onSelect={null}
                                                        filterOption={(inputValue, option) =>
                                                            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {departmentErp.map(option => (
                                                            <Select.Option key={option.value} value={option.value}>
                                                                {option.value + " - " + option.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Position"
                                                    name="position"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input defaultValue={"Programmer"} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>

                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Asset Replacement</b>} key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Status Request"
                                                    name="status_request"
                                                    rules={[{ required: true }]}
                                                    onChange={(event) => handleReplacementOrNew(event)}
                                                >
                                                    <Radio.Group name='replacement_or_new_item_radio_group'>
                                                        <Radio value="New Item">New Item</Radio>
                                                        <Radio value="Replacement">Replacement</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            {
                                                replacementOrNew === "Replacement" && (
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item

                                                            label="Existing Asset Code No."
                                                            name="existing_asset_code"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                size="middle"
                                                                placeholder="Code No."
                                                                optionFilterProp="children"
                                                                onSelect={null}
                                                                filterOption={(inputValue, option) =>
                                                                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {[{ value: "existing_asset_code", label: "existing_asset_code" }].map(option => (
                                                                    <Select.Option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Asset Status"
                                                    name="asset_status"
                                                    rules={[{ required: true }]}
                                                    onChange={(event) => handleAttachedAsset(event)}
                                                >
                                                    <Radio.Group name='asset_attached_radio_group'>
                                                        <Radio value="Single Asset">Single Asset</Radio>
                                                        <Radio value="Asset Attached Project">Attached To Project</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            {
                                                attachedAsset === "Asset Attached Project" && (
                                                    <Col xs={{ span: 23 }} sm={{ span: 9 }} xl={{ span: 9 }}>
                                                        <Form.Item

                                                            label="Project Code No."
                                                            name="project_code"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                size="middle"
                                                                placeholder="Code No."
                                                                optionFilterProp="children"
                                                                onSelect={null}
                                                                filterOption={(inputValue, option) =>
                                                                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {projectErp.map(option => (
                                                                    <Select.Option key={option.value} value={option.value}>
                                                                        {option.value + " - " + option.label}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Capex Description</b>} key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Capex Name"
                                                    name="capex_name"
                                                    required={true}
                                                    rules={[
                                                        { min: 10, message: 'Minimal 10 karakter' },
                                                        { max: 180, message: 'Maksimal 180 karakter' },
                                                    ]}
                                                >
                                                    <TextArea rows={5} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Brand Reference"
                                                    name="brand_reference"
                                                    required={true}
                                                    rules={[
                                                        { min: 10, message: 'Minimal 10 karakter' },
                                                        { max: 180, message: 'Maksimal 180 karakter' },
                                                    ]}
                                                >
                                                    <TextArea rows={5} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Reason For Request"
                                                    name="reason_request"
                                                    required={true}
                                                    rules={[
                                                        { min: 10, message: 'Minimal 10 karakter' },
                                                        { max: 180, message: 'Maksimal 180 karakter' },
                                                    ]}
                                                >
                                                    <TextArea rows={5} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Capex Price Info</b>} key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label={"Estimated Price Per Unit"}
                                                    name="estimated_price"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        type={"number"}
                                                        addonBefore={selectBefore}
                                                        defaultValue={null}
                                                        onChange={calculateTotalAmount}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label={"Unit Qty"}
                                                    name="qty"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        type={"number"}
                                                        defaultValue={0}
                                                        onChange={calculateTotalAmount}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label={"Total Amount"}
                                                    name="total_estimated"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        type={"number"} step={0.1}
                                                        addonBefore={selectBefore}
                                                        defaultValue={null}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label={"Common Operation Useful Life"}
                                                    name="operation_useful_life"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber type={"number"} addonAfter="Month" />
                                                </Form.Item>
                                            </Col>
                                            {
                                                financeStaff && (
                                                    <>
                                                        <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                            <Form.Item
                                                                label={"Fiscal Useful Life"}
                                                                name="fiscal_useful_life"
                                                                style={getFormItemStyle('fiscal_useful_life')}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Wajib Isi',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber type={"number"} addonAfter="Month" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                            <Form.Item
                                                                label={"Residual Value"}
                                                                name="residual_value"
                                                                style={getFormItemStyle('residual_value')}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Wajib Isi',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber type={"number"} max="100" addonAfter="Percent %" />
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                )
                                            }
                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Asset Custody</b>} key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Asset Operate By"
                                                    name="operate_asset_by"
                                                    rules={[{ required: true }]}
                                                    onChange={(event) => handleAssetOperateBy(event)}
                                                >
                                                    <Radio.Group name='replacement_or_new_item_radio_group'>
                                                        <Radio value="CPM">CPM</Radio>
                                                        <Radio value="Replacement">External Parties</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            {
                                                assetOperateBy === "CPM" ? (
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item

                                                            label="Department"
                                                            name="department_operate_asset"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                size="middle"
                                                                placeholder="Code No."
                                                                optionFilterProp="children"
                                                                onSelect={null}
                                                                filterOption={(inputValue, option) =>
                                                                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {departmentErp.map(option => (
                                                                    <Select.Option key={option.value} value={option.value}>
                                                                        {option.value + " - " + option.label}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item

                                                            label="Company Parties"
                                                            name="company_parties_operate"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                size="middle"
                                                                placeholder="Company Code."
                                                                optionFilterProp="children"
                                                                onSelect={null}
                                                                filterOption={(inputValue, option) =>
                                                                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {[{ value: "company_parties_operate", label: "company_parties_operate" }].map(option => (
                                                                    <Select.Option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Asset Maintenance By"
                                                    name="asset_maintenance_by"
                                                    rules={[{ required: true }]}
                                                    onChange={(event) => handleAssetMaintenanceBy(event)}
                                                >
                                                    <Radio.Group name='asset_maintenance_radio_group'>
                                                        <Radio value="CPM">CPM</Radio>
                                                        <Radio value="Replacement">External Parties</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            {
                                                assetMaintenanceBy === "CPM" ? (
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item

                                                            label="Department"
                                                            name="department_maintenance_asset"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                size="middle"
                                                                placeholder="Code No."
                                                                optionFilterProp="children"
                                                                onSelect={null}
                                                                filterOption={(inputValue, option) =>
                                                                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {departmentErp.map(option => (
                                                                    <Select.Option key={option.value} value={option.value}>
                                                                        {option.value + " - " + option.label}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item

                                                            label="Company Parties"
                                                            name="company_parties_operate"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                size="middle"
                                                                placeholder="Company Code."
                                                                optionFilterProp="children"
                                                                onSelect={null}
                                                                filterOption={(inputValue, option) =>
                                                                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {[{ value: "company_parties_operate", label: "company_parties_operate" }].map(option => (
                                                                    <Select.Option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Utilization Area</b>} key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Area Number Code"
                                                    name="area_number_code"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        size="middle"
                                                        placeholder="Area Code"
                                                        optionFilterProp="children"
                                                        onSelect={(event) => {
                                                            form.setFieldsValue({
                                                                area_description_name:
                                                                    (areaErp.find((area) => area.value === event)).label
                                                            })
                                                        }}
                                                        filterOption={(inputValue, option) =>
                                                            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {areaErp.map(option => (
                                                            <Select.Option key={option.value} value={option.value}>
                                                                {option.value + " - " + option.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Area Description Name"
                                                    name="area_description_name"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input defaultValue={"area_description_name"} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                                {
                                    financeStaff && (
                                        <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                            <Panel style={{ background: "#fce8b6" }} header={<b>Management Accounting</b>} key="1">
                                                <Row gutter={10}>
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item
                                                            label="Asset Category"
                                                            name="asset_category"
                                                            style={getFormItemStyle('asset_category')}
                                                            rules={[{ required: true, message: 'Please select an asset category!' }]}
                                                        >
                                                            <Radio.Group>
                                                                {categoryAssetErp.map((category) => (
                                                                    <Radio key={category.value} className="mt-1" value={category.value}>
                                                                        {category.value} - {category.label}
                                                                    </Radio>
                                                                ))}
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 7 }}>
                                                        <Form.Item
                                                            label="Fixed Asset Code"
                                                            name="fixed_asset_code"
                                                            style={getFormItemStyle('asset_category')}
                                                            rules={[{ required: false }]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                    )
                                }
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Activity Cost Code For Depreciation & Amortisation Expense Allocation</b>} key="1">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Activity Number Code"
                                                    name="activity_number_code"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        size="middle"
                                                        placeholder="Activity Code"
                                                        optionFilterProp="children"
                                                        onSelect={null}
                                                        filterOption={(inputValue, option) =>
                                                            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {[{ value: "activity_number_code", label: "activity_number_code" }].map(option => (
                                                            <Select.Option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Activity Description Name"
                                                    name="activity_description_name"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input defaultValue={"activity_description_name "} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                    <Row className='justify-content-center'>
                        <Col>
                            <Form.Item>
                                <Button type="primary" size="middle" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <Layout.Footer style={{ textAlign: 'center', background: "#F9F9F9" }}>©2023 Information Technology</Layout.Footer>
        </>
    );
}
export default CreateCapex